import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';
import { SettingsContext } from '../modules/layout';

export const GetStaticPageSettings = (loaded) => {
  const { node_locale } = useContext(SettingsContext);
  const { microcopy, settings } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
      settings: allContentfulSiteSettings {
        edges {
          node {
            siteName
            siteDescription
            siteAuthor
            copyright
            node_locale
            footerLogo {
              ...imageMeta
            }
            globalCss {
              globalCss
            }
            marketingCookies {
              marketingCookies
            }
            articleSocialShareComponent {
              url
              displayBreadcrumb
            }
            articleFooterComponent {
              title
              content {
                content
              }
              script {
                script
              }
              image {
                ...imageMeta
                fixed(width: 150, quality: 80) {
                  ...imageFixed
                }
                fluid(quality: 80) {
                  ...imageFluid
                }
              }
            }
            megaMenu {
              name
              visible
              page {
                fullSlug
                title
                childPages {
                  id
                  title
                  fullSlug
                }
                localizedPage {
                  id
                  title
                }
              }
              items {
                ... on ContentfulMegaMenuWrapper {
                  internal {
                    type
                  }
                  megaMenuElements {
                    ... on ContentfulMegaMenuElement {
                      name
                      visible
                      icon {
                        description
                        title
                        file {
                          url
                        }
                      }
                      internal {
                        type
                      }
                      page {
                        id
                        fullSlug
                      }
                      links {
                        ... on ContentfulMegaMenuLink {
                          internal {
                            type
                          }
                          name
                          visible
                          description
                          show
                          page {
                            ... on ContentfulPageGlobal {
                              id
                              fullSlug
                              title
                              localizedPage {
                                title
                                node_locale
                              }        
                              childPages {
                                id
                                fullSlug
                                title
                                localizedPage {
                                  title
                                  node_locale
                                }          
                              }
                            }
                            ... on ContentfulLinkExternal {
                              title
                              url: target
                            }
                          }
                        }
                        ... on ContentfulButton {
                          internal {
                            type
                          }
                          title
                          type
                          link {
                            ... on Node {
                              ... on ContentfulLinkInternal {
                                title
                                target {
                                  id
                                  title
                                  fullSlug
                                }
                                anchor
                              }
                              ... on ContentfulLinkExternal {
                                title
                                url: target
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on ContentfulMegaMenuElement {
                  name
                  cols
                  visible
                  doubleColumn
                  icon {
                    ...imageMeta
                    file {
                      url
                    }
                  }
                  internal {
                    type
                  }
                  page {
                    id
                    title
                    fullSlug
                    node_locale
                    childPages {
                      title
                      fullSlug
                    }
                    localizedPage {
                      title
                      node_locale
                    }
                    childPages {
                      id
                      title
                      fullSlug
                      node_locale
                      childPages {
                        title
                        fullSlug
                      }
                      localizedPage {
                        title
                        node_locale
                      }
                    }
                  }
                  links {
                    ... on ContentfulMegaMenuLink {
                      internal {
                        type
                      }
                      name
                      visible
                      description
                      show
                      page {
                        ... on ContentfulPageGlobal {
                          id
                          title
                          fullSlug
                          node_locale
                          childPages {
                            title
                            fullSlug
                          }
                          localizedPage {
                            title
                            node_locale
                          }
                          childPages {
                            id
                            title
                            fullSlug
                            node_locale
                            childPages {
                              title
                              fullSlug
                            }
                            localizedPage {
                              title
                              node_locale
                            }
                          }
                        }
                        ... on ContentfulLinkExternal {
                          title
                          url: target
                        }
                      }
                    }
                    ... on ContentfulButton {
                      internal {
                        type
                      }
                      title
                      type
                      link {
                        ... on Node {
                          ...link
                        }
                      }
                    }
                  }
                }
                ... on ContentfulMegaMenuPromotion {
                  name
                  description
                  button {
                    title
                    type
                    link {
                      ... on ContentfulLinkInternal {
                        id
                        target {
                          fullSlug
                          id
                        }
                        anchor
                      }
                    }
                  }
                  teaser {
                    title
                    fluid(quality: 80) {
                      src
                      sizes
                      aspectRatio
                    }
                    fixed(quality: 80) {
                      ...imageFixed
                    }
                  }
                  internal {
                    type
                  }
                }
              }
            }
          }
        }
      }
    }

    fragment imageFluid on ContentfulFluid {
      base64
      aspectRatio
      src
      srcSet
      srcWebp
      srcSetWebp
      sizes
    }
    
    fragment button on ContentfulButton {
      internal {
        type
      }
      title
      type
      link {
        ... on Node {
          ...link
        }
      }
    }

    fragment color on ContentfulColor {
      title
      baseColor
      brightness
    }

    fragment imageMeta on ContentfulAsset {
      title
      description
      file {
        url
      }
    }

    fragment imageFixed on ContentfulFixed {
      src
      srcSet
      srcSetWebp
      srcWebp
      height
      width
    }
  `);
  if (!loaded) {
    const allSiteSettings = settings.edges;
    const siteSettings = allSiteSettings.filter(
      (item) => item.node.node_locale === node_locale
    )[0].node;

    const localizedMicrocopy = {};

    microcopy.edges
      .map((edge) => edge.node)
      .forEach((node) => {
        if (node.node_locale === siteSettings.node_locale) {
          localizedMicrocopy[node.key] = node.value;
        }
      });

    siteSettings.microcopy = localizedMicrocopy;

    return siteSettings;
  }

  const allSiteSettings = settings.edges;
  const siteSettings = allSiteSettings.filter(
    (item) => item.node.node_locale === 'de'
  )[0].node;
  return siteSettings
};